
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css'
import { Route, Routes } from 'react-router-dom';
import Profile from './Profiles/Profile';
import Error from './Error';

function App() {
  return (
    <>
    <Routes>
      <Route path='/:username' element={<Profile/>}/>
      <Route path='*' element={<Error/>}/>
    </Routes>
    </>
  );
}

export default App;
