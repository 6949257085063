import { ErrorMessage, useField, Field } from 'formik';

const TextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);

    const handleKeyPress = (e) => {
        if (e.which === 32 && !e.target.value.length) e.preventDefault();
    };

    return (
        <>
            <div className="form-group ">
                <label htmlFor={field.name} className={props.asterisks || ''}>{label}</label>
                <Field
                    className={props.class || `form-control`}
                    {...field}
                    {...props}
                    onKeyPress={(e) => handleKeyPress(e)}
                />
                  <ErrorMessage name={field.name}  render={msg => <> <label htmlFor={field.name} className="error">
                                     {msg}</label></>}/>
            </div>
        </>
    );
};

export default TextField;
