import React from 'react'

const Error = () => {
    return (
        <>
            <section className="main-block not-found-page">
                <div className='not-found-img'><img src='assets/img/notFound.png' alt="notFound"/></div>
                <div className='md-title'>Profile not found</div>
            </section>
            <div className="footer-link"><a href="https://weteams.global/" target="_blank" >weteams.global</a></div>
        </>
    )
}

export default Error