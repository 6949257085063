import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import TextField from "./TextField";
import PhoneInputField from "./PhoneInputField";
import TextAreaField from "./TextAreaField";
import * as yup from 'yup';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validationSchema = yup.object({
    email: yup.string().email().required('Email field is required'),
    name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
        .min(2, 'Name must be at least 2 characters')
        .max(30, 'Name must not be greater than 30 characters')
        .required('Name field is required'),
    ///phone_number: yup.string().required('Contact number field is required'),
    message: yup
        .string()
        .required('Message field is required')
        .min(2, 'Message must be at least 2 characters')
        .max(1000, 'Message must not be greater than 1000 characters'),
});


const MailMe = ({ profile }) => {
  const { email, email_caption, name, username } = profile;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const clickme = () => setShow(true);

  const contactUs = (fields) => {


    const headers = {
        'Content-Type': 'application/json',
      }

     console.log(fields)
    try {
       // setIsLoading(true); // start loading spinner
        axios
            .post('http://weteam.wedighq.com/api/v1/business-enquiry', fields,{
                headers: headers
              })
            .then((response) => {
                let result = response.data;
                
                ///console.log(result);
                if (result.status) {
                   console.log(result.message)
                    toast.success(result.message);
                }
                if (result.status == false) {
                   toast.error(result.message);
                }
                //setIsLoading(false); // stop loading spinner
            })
            .catch((error) => {
                //setIsLoading(false); // stop loading spinner
                console.log(error);
            });
    } catch (error) {
        console.log(error);
    }
};

 
  return (
    <>
    <ToastContainer />
      {console.log(profile.name)}
      {/* <button className="social-box" onClick={clickme}><img src="assets/img/envlope-icon.svg" /></button> */}
      <button className="white-btn" onClick={clickme}>
        {email_caption}
      </button>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        className="about-modal"
      >
        <Modal.Header>
          <Modal.Title>Connect with {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              name: "",
              email: "",
              username:username,
              phone_number: "",
              phone_code:"",
              message: "",
              contact_with:email
            }}
            onSubmit={(values, { resetForm }) => {

                //console.log(values);
              // data send serve side
              contactUs(values);
              resetForm();
            }}
          >
            <Form className="contact-form">
              <TextField
                label="Full Name"
                asterisks="asterisks"
                type="text"
                placeholder="Enter your full name"
                name="name"
              />

              <TextField
                label="Email Address"
                asterisks="asterisks"
                type="email"
                placeholder="local@domain.com"
                name="email"
              />

              <Field
                name="phone_number"
                placeholder="Enter your phone number"
                component={PhoneInputField}
                />
              <TextAreaField
                label="Message"
                asterisks="asterisks"
                placeholder="Write your message"
                name="message"
              />
              <div className="text-center mt-30">
                <Button className="theme-dark-transparent-btn" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  type="submit"
                  className="theme-dark-btn ml-15"
                >
                  Send Message
                </Button>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MailMe;
