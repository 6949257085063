import { ErrorMessage, useField, Field } from 'formik';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const PhoneInputField = ({ field, form }) => {
    const re = /^[0-9\b]+$/;
    return (
        <>
            <div className="form-group">
                <label htmlFor="phone_number" className='asterisks'>Contact Number</label>
                <div className='phone-field'>
                    <IntlTelInput
                        containerClassName="intl-tel-input"
                        inputClassName="form-control"
                        fieldName="phone_number"
                        value={field.value}
                        separateDialCode={true}
                        onPhoneNumberChange={(isValid, number, country) => {
                            if (number === '' || re.test(number)) {
                                form.setFieldValue("phone_code", "+"+country.dialCode);
                                form.setFieldValue("country_short_code",country.iso2);
                                form.setFieldValue("phone_number", number);
                            }
                        }}
                        preferredCountries={['ca','us','gb','sg','ae']}
                        placeholder="Enter your contact number"
                        />
                    </div>
                    <ErrorMessage name={field.name}  render={msg => <> <label htmlFor={field.name} className="error">
                                     {msg}</label></>}/>
            </div>
        </>
    );
};

export default PhoneInputField
