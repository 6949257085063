import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MailMe from '../Common/MailMe';

const Profile = () => {
  const [profileData, setProfileData] = useState([]);
  const userName = useParams();
  const myProfileData = async () => {
    fetch(
      `https://admin.weteams.io/api/v1/business-card/${userName.username}`
    )
      .then((res) => res.json())
      .then((json) => {
        setProfileData(json.data);
      });
  };
  useEffect(() => {
    myProfileData();
  }, []);
  return (
    <>
      <section className="main-block">
        <div className="circle-box">
          <img src={profileData.image} />
        </div>
        <div className="employee-name">{profileData.name}</div>
        <div className="employee-post">{profileData.designation}</div>
        {/* <div className="social-block">
          <div>
             <MailMe profileName={"Anand Mathur"}/>
            <a href="#" className="social-box">
              <img src="assets/img/envlope-icon.svg" />
            </a>
          </div>
          <div>
            <a href={profileData.facebook} className="social-box" target="_blank">
              <img src="assets/img/fb-icon.svg" />
            </a>
          </div>
          <div>
            <a href={profileData.instagram} className="social-box" target="_blank">
              <img src="assets/img/insta-icon.svg" />
            </a>
          </div>
          <div>
            <a href={profileData.facebook} className="social-box" target="_blank">
              <img src="assets/img/lin-icon.svg" />
            </a>
          </div>
          <div>
            <a href={profileData.twitter} className="social-box" target="_blank">
              <img src="assets/img/tw-icon.svg" />
            </a>
          </div>
        </div> */}
        <div className="btns-block">
          <div>
            <MailMe profile={profileData}/>
            {/* <a href={profileData.email} target="_blank"> */}
              {/* <button className="white-btn">{profileData.email_caption}</button> */}
            {/* </a> */}
            {/* </MailMe> */}
          </div>
          <div>
            <a href={profileData.linkedin} target="_blank">
              <button className="white-btn">
                {profileData.linkedin_caption}
              </button>
            </a>
          </div>
          <div>
            <a href={profileData.facebook} target="_blank">
              <button className="white-btn">
                {profileData.facebook_caption}
              </button>
            </a>
          </div>
          <div>
            <a href={profileData.instagram} target="_blank">
              <button className="white-btn">
                {profileData.instagram_caption}
              </button>
            </a>
          </div>
          <div>
            <a href={profileData.twitter} target="_blank">
              <button className="white-btn">
                {profileData.twitter_caption}
              </button>
            </a>
          </div>
        </div>
      </section>
      <div className="footer-link">
        <a href="https://weteams.global/" target="_blank">
          weteams.global
        </a>
      </div>








    </>
  );
};

export default Profile;
